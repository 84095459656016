import {
  Box,
  Flex,
  HStack,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  VStack,
  Badge
} from '@chakra-ui/react';
import { Harmburger } from 'assets/icons/Harmburger';
import { responsiveLg } from 'utils';
import PRLogo from 'assets/images/PRLogo.svg';
import { UserIcon } from 'assets/icons/UserIcon';
import { useNavigate } from 'react-router-dom';
import { Routes } from 'constant';
import { observer } from 'mobx-react-lite';
import { useStore } from 'store';
import { CloseIcon } from 'assets/icons/CloseIcon';
import notify2 from 'assets/images/notify2.svg';
import storage from 'utils/storage';
import dashboard from 'assets/images/dashboard.svg';
import order from 'assets/images/order2.svg';
import customer from 'assets/images/customer.svg';
import inventory from 'assets/images/inventory.svg';
import runner from 'assets/images/runner.svg';
import partner from 'assets/images/partner.svg';
import report from 'assets/images/report.svg';
import coupon from 'assets/images/coupon.svg';
import campaign2 from 'assets/images/campaign2.png';
import campaign from 'assets/images/campaign.png';
import dashboard2 from 'assets/images/dashboard2.svg';
import order2 from 'assets/icons/order2.svg';
import customer2 from 'assets/images/customer2.svg';
import inventory2 from 'assets/images/inventory2.svg';
import runner2 from 'assets/images/runner2.svg';
import partner2 from 'assets/images/partner2.svg';
import report2 from 'assets/images/report2.svg';
import services1 from 'assets/icons/services1.svg';
import services2 from 'assets/icons/services2.svg';
import { FaSearch } from 'react-icons/fa';
import React, { useEffect } from 'react';
import { subscribeNotification } from 'requests/order';
import audioUrl from '../../assets/order.mp3';
import amberAlertUrl from '../../assets/amber-alert.mp3';
import NotificationCard from 'components/notification';
import {
  getFCMToken,
  initializeFirebase,
  onMessageListener,
  requestPermission
} from 'utils/firebase';
import Permissions from 'utils/permissions';

const getAdminMenuList = (): IAdminMenuList[] => [
  {
    path: Routes.AdminDashboard,
    name: 'Dashboard',
    isAuthenticated: true,
    icon: dashboard,
    iconActive: dashboard2,
    active: false,
    count: 0
  },
  {
    path: Routes.AdminOrdersList,
    name: 'Orders',
    isAuthenticated: true,
    icon: order,
    iconActive: order2,
    active: false,
    count: 0,
    permission: 'Orders'
  },
  {
    path: Routes.AdminSubscriptionList,
    name: 'Subscription',
    isAuthenticated: true,
    icon: order,
    iconActive: order2,
    active: false,
    count: 0,
    permission: 'Orders'
  },
  {
    path: Routes.AdminServices,
    name: 'Services',
    isAuthenticated: true,
    icon: services1,
    iconActive: services2,
    active: false,
    count: 0,
    permission: 'Services'
  },
  {
    path: Routes.AdminCustomer,
    name: 'Customers',
    isAuthenticated: true,
    icon: customer,
    iconActive: customer2,
    active: false,
    count: 0,
    permission: ['Customer', 'User']
  },
  {
    path: Routes.AdminInventory,
    name: 'Inventory',
    isAuthenticated: true,
    icon: inventory,
    iconActive: inventory2,
    active: false,
    count: 0,
    permission: 'Inventory'
  },
  {
    path: Routes.AdminRunners,
    name: 'Runners',
    isAuthenticated: true,
    icon: runner,
    iconActive: runner2,
    active: false,
    count: 0,
    permission: 'Runners'
  },
  {
    path: Routes.AdminPartners,
    name: 'Partners',
    isAuthenticated: true,
    icon: partner,
    iconActive: partner2,
    active: false,
    count: 0,
    permission: 'Partners'
  },
  {
    path: Routes.AdminReports,
    name: 'Report',
    isAuthenticated: true,
    icon: report,
    iconActive: report2,
    active: false,
    count: 0,
    permission: 'Report'
  },
  {
    path: `${Routes.AdminCoupon}`,
    name: 'Coupon',
    isAuthenticated: true,
    icon: coupon,
    iconActive: coupon,
    active: false,
    count: 0,
    permission: 'Coupon'
  },
  {
    path: `${Routes.AdminCampaign}`,
    name: 'Campaign',
    isAuthenticated: true,
    icon: campaign2,
    iconActive: campaign,
    active: false,
    count: 0,
    permission: 'Campaign'
  }
];

const handleLogout = (isAdmin: boolean) => {
  storage.logout();
  window.location.href = isAdmin ? Routes.AdminLogin : Routes.AdminLogin;
};

const AdminProfileDropdown = observer(() => {
  const {
    AppStore: {
      adminNotifications,
      addToNotification,
      soundTarget,
      isNotificationOpen,
      toggleNotificationBell,
      setSoundTarget
    },
    AuthStore: { user }
  } = useStore();

  const audioButtonRef = React.useRef<HTMLButtonElement | null>(null);

  const playNotificationSound = () => {
    const soundUrl = soundTarget === 'single' ? amberAlertUrl : audioUrl;
    const audio = new Audio(soundUrl);
    audio
      .play()
      .then(() => console.log('Notification sound played.'))
      .catch((error) => console.error('Error playing notification sound:', error));
  };

  const notifyUserAndPlaySound = (payload: any) => {
    const { notification } = payload;
    if (notification && notification.body) {
      try {
        const cleanedData = JSON.parse(notification.body);
        cleanedData.read = false;
        addToNotification(cleanedData);
        const soundType = cleanedData.device || 'group';
        setSoundTarget(soundType);

        // Play the notification sound with the determined sound URL
        playNotificationSound();
      } catch (error) {
        console.error('Error parsing notification body:', error);
      }
    }
  };

  const initializeApp = async () => {
    const { app, messaging } = await initializeFirebase();
    if (app && messaging) {
      // Request notification permission
      const permission = await requestPermission();
      if (permission) {
        // Set the Firebase Messaging instance
        handlegetFCMToken();
        onMessageListener(messaging, notifyUserAndPlaySound);
      }
    }
  };

  const handlegetFCMToken = () => {
    try {
      getFCMToken().then((firebaseToken) => {
        if (firebaseToken) {
          const payload = {
            token: firebaseToken
          };
          if (user.role) {
            subscribeNotification(payload);
          }
        }
      });
    } catch (error) {
      console.log(`Error: ${error}`);
    }
  };

  useEffect(() => {
    initializeApp();

    // Remove this event listener
    window.addEventListener('playNotificationSound', playNotificationSound);

    const handleServiceWorkerMessage = (event: {
      data: { type: string; notification: any; data: any };
    }) => {
      if (event.data && event.data.type === 'message') {
        notifyUserAndPlaySound(event.data);
      }
      if (event.data && event.data.type === 'notificationData') {
        const notification = event.data.data;
        console.log({ worker: notification });
        notification.read = false;
        addToNotification(notification);
        notifyUserAndPlaySound(event.data);
      }
    };

    navigator.serviceWorker.addEventListener('message', handleServiceWorkerMessage);

    return () => {
      // Remove the removed event listener here
      window.removeEventListener('playNotificationSound', playNotificationSound);
      navigator.serviceWorker.removeEventListener('message', handleServiceWorkerMessage);
    };
  }, []);

  return (
    <Flex w={{ base: '70%', md: '90%' }} align="center">
      <Flex
        w="35px"
        flexShrink="0"
        h="35px"
        borderRadius="10px"
        p=".3rem"
        bg="#E7EDF0"
        position="relative"
      >
        <Image src={notify2} alt="notification" cursor="pointer" onClick={toggleNotificationBell} />
        {adminNotifications.length > 0 && (
          <Badge
            bg="secondary.main"
            rounded="full"
            fontSize="0.75rem"
            w="1.5em"
            h="1.5em"
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderRadius="50%"
            fontWeight="500"
            color="white"
            position="absolute"
            top="-0.8em"
            right="-0.8em"
          >
            <Text fontSize="0.65rem">
              {adminNotifications.length > 99 ? '99+' : adminNotifications.length}
            </Text>
          </Badge>
        )}
        <button
          ref={audioButtonRef}
          id="audioButton"
          style={{ display: 'none' }}
          onClick={playNotificationSound}
        >
          Play Notification Sound
        </button>
      </Flex>

      {isNotificationOpen && <NotificationCard />}
      <Menu>
        <MenuButton minW="80%">
          <HStack spacing="10px" minW="80%">
            <VStack spacing="1px" minW="80%" textAlign="center">
              <Text fontSize={{ base: '0.65rem', md: '0.75rem' }} color="#541AA9" fontWeight="400">
                Admin
              </Text>
              <Text fontSize={{ base: '0.65rem', md: '0.75rem' }}>
                {(user?.role as unknown as string)
                  ?.toLowerCase()
                  ?.replace(/^\w/, (c: string) => c.toUpperCase()) || ''}
              </Text>
            </VStack>
            <UserIcon />
          </HStack>
        </MenuButton>
        <MenuList>
          <MenuItem onClick={() => handleLogout(false)}>Logout</MenuItem>
        </MenuList>
      </Menu>
    </Flex>
  );
});

export const NavMenu = () => {
  const {
    AppStore: { setSearchQuery }
  } = useStore();
  const debounce = (func: (...args: any[]) => void, delay: number) => {
    let timer: NodeJS.Timeout;
    return (...args: any[]) => {
      clearTimeout(timer);
      timer = setTimeout(() => func(...args), delay);
    };
  };

  const debouncedSetSearchQuery = debounce(setSearchQuery, 300)
  const handleSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.currentTarget.value;

    const allowedPattern = /^[a-zA-Z0-9\s-]*$/;
    if (allowedPattern.test(inputValue)) {
      debouncedSetSearchQuery(inputValue);
    }
  };

  return (
    <Flex justify="end" align="center" width="100%">
      <InputGroup ml={{ md: '9rem' }} borderRadius="50%">
        <InputLeftElement pointerEvents="none" children={<FaSearch />} color="#BCC4C8" />
        <Input
          w={{ base: '100%', md: '70%' }}
          type="search"
          placeholder="Search..."
          bg="#F9FAFC"
          boxShadow="inner"
          fontSize={{ base: '1rem', md: '0.875rem' }}
          onInput={handleSearchInput}
        />
      </InputGroup>
    </Flex>
  );
};

const DropdownMenu = observer(({ menu }: { menu: IAdminMenuList[] }) => {
  const {
    AppStore: { toggleMenu, menuOpen },
    AuthStore
  } = useStore();
  const permissions = new Permissions(AuthStore);
  const navigate = useNavigate();

  const goToUrl = (url: string) => {
    if (menuOpen) toggleMenu();
    navigate(url);
  };

  return (
    <VStack
      sx={{
        borderRadius: '8px',
        border: '1px solid rgba(0, 0, 0, 0.1)',
        padding: '16px',
        margin: '8px',
        alignItems: 'flex-start'
      }}
      display={responsiveLg('flex', 'none')}
    >
      {menu.map(
        (item) =>
          !item.permission ||
          (item.permission && permissions.hasCategoryAccess(item.permission) && (
            <Flex
              alignItems="center"
              gap="1rem"
              sx={{
                height: '37px',
                borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                width: '100%',
                paddingLeft: '10px'
              }}
              onClick={() => goToUrl(item.path)}
              key={item.path}
            >
              {' '}
              {item.active ? (
                <Image src={item.icon} alt={item.name} />
              ) : (
                <Image src={item.iconActive} alt={item.name} />
              )}
              {item.name}
            </Flex>
          ))
      )}
    </VStack>
  );
});

const AdminNavbar = () => {
  const navigate = useNavigate();
  const {
    AppStore: { toggleMenu, menuOpen }
  } = useStore();
  const menuList: IAdminMenuList[] = getAdminMenuList();

  return (
    <Box>
      <Flex
        as="nav"
        justify="space-between"
        align="center"
        height="60px"
        borderBottom="1px solid #D9D9D9"
        px={{ base: '25px', md: '55px' }}
      >
        <HStack gap={{ lg: '4rem' }} w="80%">
          {menuOpen ? (
            <CloseIcon display={responsiveLg('unset', 'none')} onClick={() => toggleMenu()} />
          ) : (
            <Harmburger display={responsiveLg('unset', 'none')} onClick={() => toggleMenu()} />
          )}
          <Image
            onClick={() => navigate(Routes.AdminDashboard)}
            width={{ md: '130px' }}
            sx={{ cursor: 'pointer' }}
            src={PRLogo}
            alt="logo"
          />
          <Box display={responsiveLg('none', 'unset')} w="80%">
            <NavMenu />
          </Box>
        </HStack>
        <Box
          minW={{ base: '80%', md: '20%' }}
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
        >
          {<AdminProfileDropdown />}
        </Box>
      </Flex>
      {menuOpen && (
        <>
          <DropdownMenu menu={menuList} />
        </>
      )}
    </Box>
  );
};

export default observer(AdminNavbar);
